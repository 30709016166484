import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { IS_APP } from "@/__main__/constants.mjs";
import { setRoute } from "@/__main__/router.mjs";
import {
  ACTIVE_GAMES,
  GAME_BOX_ICONS,
  GAME_PREFERRED_NAME_MAP,
} from "@/app/constants.mjs";
import { STREAMER_OVERLAYS } from "@/hub-overlays/constants.mjs";
import OverlayContent from "@/hub-overlays/OverlayContent.jsx";
import { CardContainer } from "@/hub-overlays/Overlays.style.jsx";
import { useOverlays } from "@/library/actions.mjs";
import OverlayConfigureModal from "@/library/OverlayConfigureModal.jsx";
import { useFavoriteGames } from "@/shared/GameFilterBar.jsx";
import HubNoContent from "@/shared/HubNoContent.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import ClientOnly from "@/util/ClientOnly.jsx";
import findByKey from "@/util/find-by-key.mjs";
import isEmpty from "@/util/is-empty.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import SymbolMap from "@/util/symbol-map.mjs";
import useCallbackRef from "@/util/use-callback-ref.mjs";

const TabContent = styled("div")`
  max-width: var(--sp-container);
  display: flex;
  flex-direction: column;
  gap: var(--sp-4);
  .game-title-section {
    display: flex;
    gap: var(--sp-3);
    align-items: center;
    margin-bottom: var(--sp-3_5);
  }
`;

function AllOverlays({ game: selectedGame = null }) {
  const route = useRoute();
  const favoriteGames = useFavoriteGames();

  const selectedOverlayId = route.searchParams.get("select");

  const updateOverlayId = useCallback(
    (value) => {
      setRoute(
        route.currentPath,
        value ? new URLSearchParams([["select", value]]) : undefined,
      );
    },
    [route.currentPath],
  );

  const gameOverlays = useOverlays(selectedGame);

  const overlays = useMemo(() => {
    if (!gameOverlays) return;

    const allOverlays = {};
    const all = (
      selectedGame
        ? Object.values(gameOverlays)
        : SymbolMap.values(gameOverlays).flatMap((o) => Object.values(o))
    )
      .sort(
        (a, b) =>
          (a.disabled?.({}) ? 1 : 0) - (b.disabled?.({}) ? 1 : 0) ||
          a.id.localeCompare(b.id),
      )
      .sort((a, b) => b.tags.includes("new") - a.tags.includes("new"))
      .reduce((games, overlay) => {
        allOverlays[overlay.id] = overlay;
        if (games[overlay.game]) {
          games[overlay.game].push(overlay);
        } else {
          games[overlay.game] = [overlay];
        }
        return games;
      }, {});
    const lookup = selectedGame ? gameOverlays : allOverlays;

    Object.assign(all, { lookup });

    return all;
  }, [gameOverlays, selectedGame]);

  const filteredGames = useMemo(() => {
    if (!selectedGame) {
      return (!IS_APP ? ACTIVE_GAMES : favoriteGames).filter(
        (gameSymbol) => overlays[gameSymbol],
      );
    }

    return [selectedGame];
  }, [selectedGame, overlays, favoriteGames]);

  const selectedOverlay =
    overlays?.lookup[selectedOverlayId] ??
    findByKey(STREAMER_OVERLAYS, "id", selectedOverlayId);

  // happens after the modal has been minimized
  const onOverlayClosed = useCallback(() => {
    updateOverlayId(null);
  }, [updateOverlayId]);

  const overlayModalRef = useCallbackRef(
    (node) => {
      if (!selectedOverlayId) return node.close();
      if (!node.open) {
        node.showModal();
      }
      return () => {
        node.close();
      };
    },
    [selectedOverlayId],
  );

  const { t } = useTranslation();

  if (isEmpty(overlays)) return <HubNoContent />;

  let numOverlays = 0;

  return (
    <TabContent>
      <PageHeader title={t("common:navigation.overlays", "Overlays")} />

      {filteredGames.map((gameSymbol) => {
        const BoxIcon = GAME_BOX_ICONS[gameSymbol];

        return (
          <div key={String(gameSymbol)}>
            {!selectedGame && (
              <div className="type-h6 game-title-section">
                <BoxIcon />
                {t(...GAME_PREFERRED_NAME_MAP[gameSymbol])}
              </div>
            )}

            <CardContainer>
              {overlays[gameSymbol]?.map((item) => (
                <OverlayContent
                  key={item.id}
                  overlay={item}
                  showGameIcon={!gameSymbol}
                  loading={numOverlays++ > 8 ? "lazy" : undefined}
                />
              ))}

              {Object.entries(STREAMER_OVERLAYS[gameSymbol] ?? {}).map(
                ([id, item]) => (
                  <OverlayContent
                    key={id}
                    overlay={item}
                    showGameIcon={!gameSymbol}
                    loading="lazy"
                  />
                ),
              )}
            </CardContainer>
          </div>
        );
      })}

      {selectedOverlay && (
        <ClientOnly>
          <OverlayConfigureModal
            overlay={selectedOverlay}
            ref={overlayModalRef}
            handleClose={onOverlayClosed}
          />
        </ClientOnly>
      )}
    </TabContent>
  );
}

export default AllOverlays;
